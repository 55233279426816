#react-paginate{
   
}
#react-paginate ul {
    display: inline-block;
    margin-left: 20px;
    padding-left: 0;
}

#react-paginate li {
    display: inline-block;
    border: 1px solid rgb(224, 224, 224);
    color: #000;
    cursor: pointer;
    margin-right: 3px;
    border-radius: 5px;
    margin-bottom: 5px;
}

#react-paginate li {
    padding: 2px 5px;
    display: inline-block;
    color: #000;
    outline: none;
}

#react-paginate li.selected {
    background-color: #6b1919;
    color: #fff;
    outline: none;
}

